// @ts-strict-ignore
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useLandingCourses } from '../../modules/apis/tutor/landing'
import { useOpenMaterialPopup } from '../../modules/hooks/common/useOpenMaterialPopup'
import { s_landing_review } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import SectionTitle from './SectionTitle'

const LandingMaterials = (props) => {
  const { t, locale } = useTranslation()
  const { sectionTitle, initData } = props
  const [activeId, setActiveId] = useState(null)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  useEffect(() => {
    setIsMobile(window.innerWidth < 576 ? true : false)
  }, [])

  const { data } = useLandingCourses()

  useEffect(() => {
    if (initData) {
      setActiveId(0)
    }
    if (data?.success) {
      setActiveId(0)
    }
  }, [data?.success, initData, locale])

  const handleActiveId = (id) => {
    setActiveId(id)
  }

  const showMaterialPopup = useOpenMaterialPopup()

  return (
    <section className={classNames('py-32', 'relative z-[1] overflow-hidden bg-[#F5F5F5]')}>
      <div className="overflow-visible">
        {sectionTitle ? (
          sectionTitle
        ) : (
          <SectionTitle
            title={t(s_landing_review.find_out_interested)}
            subtitle={t(s_landing_review.whenever_wherever)}
            submuted
          />
        )}
      </div>
    </section>
  )
}

export default LandingMaterials
