import { useEffect } from 'react'

const useTriggerResize = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dispatchEvent(new Event('resize'))
    }
  }, [])
}
export default useTriggerResize
