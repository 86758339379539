// @ts-strict-ignore
import classNames from 'classnames'
import { ReactElement } from 'react'
import { ICON_CHEVRON_RIGHT_PRIMARY } from '../../../modules/svg/svg'

export default function SliderNextBtn({
  onClick,
  translateX,
  right = '-6px',
  top = '50%',
}: {
  onClick?: any
  translateX?: string | number
  right?: string | number
  top?: string | number
}): ReactElement {
  return (
    <div
      className={classNames(
        onClick === null
          ? 'hidden'
          : 'absolute z-10 flex -translate-y-24 transform cursor-pointer items-center justify-center rounded-full bg-white shadow-md',
        translateX ? `translate-x-${translateX}` : ' translate-x-22'
      )}
      style={{ width: '40px', height: '40px', right, top }}
      onClick={onClick}
    >
      <img src={ICON_CHEVRON_RIGHT_PRIMARY} />
    </div>
  )
}
