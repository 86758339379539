// @ts-strict-ignore
import classNames from 'classnames'
import Col from '../basic/Col'

const SectionTitle = ({ title, ...props }) => {
  const { uptitle, toptitle, subtitle, badge, submuted } = props
  return (
    <div className="row flex justify-center pb-32">
      <Col clx="col-12 md:col-10 lg:col-8 text-center">
        {toptitle && <h4 className="mb-32 text-gray-500">{toptitle}</h4>}
        {uptitle && <h4 className="mt-12 mb-0">{uptitle}</h4>}
        <div className="text-bold mb-0">{title}</div>
        {badge && badge}
        {subtitle && <h4 className={classNames('mt-8 mb-0', { 'text-gray-500': submuted })}>{subtitle}</h4>}
      </Col>
    </div>
  )
}

export default SectionTitle
