import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { LOCALE_EN } from '../../i18n/config'
import { landingCoursesQueryApi } from '../../react-query/queryApis'

//* ============================================================================================================== */

interface ILandingCourses {
  success: boolean
  categories: any
}

export const useLandingCourses = () => {
  const searchParams = { locale: LOCALE_EN }

  return useQuery<ILandingCourses, AxiosError>({
    queryKey: landingCoursesQueryApi.getQueryKey({ searchParams }),
    queryFn: landingCoursesQueryApi.getQueryFn({ searchParams }),

    // onError: (e) => handleAxiosError(e),
  })
}
