// @ts-strict-ignore
import classNames from 'classnames'
import { ReactElement } from 'react'
import { ICON_CHEVRON_LEFT_PRIMARY } from '../../../modules/svg/svg'

export default function SliderPrevBtn({
  onClick,
  isDailyBrief,
  left = '-6px',
  top = '50%',
  right = 'auto',
}: {
  onClick?: any
  isDailyBrief?: boolean
  left?: string | number
  top?: string | number
  right?: string
}): ReactElement {
  return (
    <div
      className={classNames(
        onClick === null ? 'hidden' : isDailyBrief && 'slick-prev-dailybrief',
        'absolute top-1/2 z-10 flex -translate-y-24 -translate-x-6 transform cursor-pointer items-center justify-center rounded-full bg-white shadow-md'
      )}
      style={{ width: '40px', height: '40px', left, top, right }}
      onClick={onClick}
    >
      <img src={ICON_CHEVRON_LEFT_PRIMARY} />
    </div>
  )
}
