// @ts-strict-ignore
import { useBasicPopup } from '../../../components/basic/popup/BasicPopup'
import MaterialBottomSheet from '../../../components/modal/MaterialBottomSheet'
import MaterialModal from '../../../components/modal/MaterialModal'
import { axiosGet } from '../../../modules/axiosHelper'
import { tutor_apis } from '../../apis'
import { useBottomSheet } from './BottomSheet'
import useIsMobile from './useIsMobile'

export const useOpenMaterialPopup = () => {
  const basicPopup = useBasicPopup()
  const isMobileEnv = useIsMobile()
  const bottomSheet = useBottomSheet()

  return async (e, id, isMobile?) => {
    e.preventDefault()
    e.stopPropagation()
    const res = await axiosGet(tutor_apis.resources.courses.detail(id))

    if (res.success) {
      if (!isMobileEnv) {
        basicPopup.show({
          id: 'packet-modal',
          maxW: 1096,
          maxH: 'calc(84vh - 98px)',
          bodyPx: 40,
          titlePx: 40,
          titlePt: 40,
          titlePb: 24,
          titleCentered: false,
          title: res.course.title,
          wrapperClassName: 'border-1 border-gray-300 mx-[40px] mb-[40px] rounded-[4px]',
          body: <MaterialModal data={res} isMobile={isMobile} isTrial={false} />,
        })
      } else {
        bottomSheet.show({
          id: 'material-bottom-sheet',
          body: <MaterialBottomSheet data={res} />,
        })
      }
    }
  }
}
