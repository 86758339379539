import { useState } from 'react'
import { MaterialModalSubTab, ModalBodyComponent } from './MaterialModal'

export interface IMaterialData {
  success: boolean
  course: IMaterialDataCourse
  keyquestions: Keyquestion[]
  expressions: any[]
}

export interface IMaterialDataCourse {
  article: Article[]
  category: string
  course_type: string
  category_id: null
  event_course: boolean
  featured_article: null
  free_talking: boolean
  id: number
  image_long_url: string
  image_wide_url: string
  image_url: string
  is_mine: boolean
  is_nyt: boolean
  is_revised: null
  is_short: boolean
  is_speaking: boolean
  is_writing: boolean
  large_category_id: null
  level: number
  grade: number
  mp3_link: string
  mp3_url_array: any[]
  nyt_image_url: null
  pdf_link: string
  pdf_link_en: string
  playback_time: number
  provide_feedback: boolean
  subtitle: string
  subtitle1: string
  subtitle2: string
  subtitle2_kr: string
  summary: null
  summary_eng: string
  summary_kor: string
  title: string
  title_en: string
  subtitle_kr: string
  subtitle_en: string
  title_kr: string
  video: any[]
  youtube: null
  keyquestions: Keyquestion[]
  expressions: any[]
  related_contents: RelatedContents
  published_at: string
  updated_at: string
  is_today: boolean
  mp3_running_time: number
  mp3_running_time_in_minute: number
}

export interface Article {
  id: number
  url: string
  title: string
}

export interface Keyquestion {
  id: number
  text: string
  text_ko?: string
  question_number: string
  img_url: null
}

export interface RelatedContents {
  webinar: any[]
  course: CourseElement[]
}

export interface CourseElement {
  id: number
  title: string
  subtitle: string
  image_url: string
  category: string
  is_mine: boolean
  is_my_material: boolean
  is_revised: null
  provide_feedback: boolean
  pdf_link_en: string
  level: number
  course_type: string
  is_today: boolean
  mp3_running_time: number
  mp3_running_time_in_minute: number
}

const MaterialBottomSheet = ({ data }: { data: IMaterialData }) => {
  const [activeTabIdx, setActiveTabIdx] = useState<number>(0)

  return (
    <div className="relative max-h-[90vh]">
      <div className="sticky mt-[53px] h-[52px]">
        <MaterialModalSubTab activeTabIdx={activeTabIdx} setActiveTabIdx={setActiveTabIdx} />
      </div>
      <div className="flex h-[calc(90vh_-_121px)] overflow-y-scroll pt-[20px]">
        <ModalBodyComponent
          isModal={true}
          activeTabIdx={activeTabIdx}
          materialData={data}
          materialId={data?.course?.id}
        />
      </div>
    </div>
  )
}

export default MaterialBottomSheet
