// @ts-strict-ignore
import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import Typed from 'typed.js'
import Col from '../../../components/basic/Col'
import AvatarImg from '../../../components/common/AvatarImg'
import CenterContainer from '../../../components/common/CenterContainer'
import { ClockIcon } from '../../../components/common/Icons'
import SliderNextBtn from '../../../components/common/icons/SliderNextBtn'
import SliderPrevBtn from '../../../components/common/icons/SliderPrevBtn'
import LandingMaterials from '../../../components/landing-legacy/LandingMaterials'
import { sendGTMBasicClickEvent } from '../../../helpers/gtmHelper'
import { CurrentUser } from '../../../model/Common/common.interface'
import { tutor_apis } from '../../../modules/apis'
import { axiosGet } from '../../../modules/axiosHelper'
import useTriggerResize from '../../../modules/hooks/common/useTriggerResize'
import LinkLocale from '../../../modules/i18n/LinkLocale'
import LoginLinkA from '../../../modules/i18n/LoginLinkA'
import { TUTOR_LANDING_BTN_ID } from '../../../modules/ids/gtm/tutor'
import { userQueryApi } from '../../../modules/react-query/queryApis'
import { REFERRAL_ICON_LETTER } from '../../../modules/svg/svg'
import urls from '../../../modules/urls'
import { CDN_URL, TUTOR_STATUS } from '../../../modules/vars'
import Button from '../../_legacy/Button'

const BandBanner = ({ name }) => {
  const changedName = name.slice(0, -3) + '***'
  return (
    <LinkLocale href={urls.tutor.auth.signUp}>
      <div className="text-14 w-full cursor-pointer bg-purple-500 bg-opacity-20 pt-8 pb-6">
        <div className="max-w-1040 mx-auto flex items-center justify-start px-20 ">
          <img className="h-[28px]" src={REFERRAL_ICON_LETTER} />
          <div className="ml-16 mr-4 font-medium text-purple-500">{changedName}</div>
          <div> invites you to join Ringle as tutor.</div>
        </div>
      </div>
    </LinkLocale>
  )
}

const SectionClass = 'py-96'

type TutorHomePageChildrenPropsType = {
  mobile?: boolean
  currentUser?: CurrentUser
  videoRef?: any
  ctaId?: any
}

const EnterOrSignUp = ({ currentUser, ctaId }: TutorHomePageChildrenPropsType) => {
  const router = useRouter()

  const getRedirectUrl = (currentUser) => {
    if (currentUser.not_allowed_log_in) {
      switch (currentUser.tutor_status_id) {
        case TUTOR_STATUS.DORMANT:
          return urls.tutor.landing.dormant
        case TUTOR_STATUS.OUT_THREE_STRIKE:
          return urls.tutor.landing.strike
        case TUTOR_STATUS.OUT_TEN_STRIKE:
          return urls.tutor.landing.strike
        case TUTOR_STATUS.HOLD:
          return urls.tutor.landing.holding
        case TUTOR_STATUS.BLACKLIST:
          return urls.tutor.landing.holding
        default:
          return urls.tutor.portal.home
      }
    } else {
      return urls.tutor.portal.home
    }
  }

  return currentUser ? (
    <>
      {currentUser.status_sub_url ? (
        <LinkLocale clx=" lift block" href={currentUser.status_sub_url}>
          <Button primary md mt32 onClick={() => sendGTMBasicClickEvent(ctaId)}>
            Get Started
          </Button>
        </LinkLocale>
      ) : (
        <LinkLocale clx=" lift block" href={getRedirectUrl(currentUser)}>
          <Button primary md mt32 onClick={() => sendGTMBasicClickEvent(ctaId)}>
            Get Started
          </Button>
        </LinkLocale>
      )}
    </>
  ) : (
    <>
      <LoginLinkA tutor href={urls.tutor.auth.signUp} clx="lift block">
        <Button primary md mt32 onClick={() => sendGTMBasicClickEvent(ctaId)}>
          Get Started
        </Button>
      </LoginLinkA>
    </>
  )
}

const Welcome = ({ mobile, currentUser }: TutorHomePageChildrenPropsType) => {
  useEffect(() => {
    const options = {
      strings: ['Teach', 'Grow', 'Earn'],
      typeSpeed: 70,
      startDelay: 100,
      backSpeed: 70,
      backDelay: 2000,
      loop: true,
      fontWeight: 700,
      classNames: 'text-q-purple-500',
    }
    const typed = new Typed('#typeAnimation', options)

    return () => {
      typed.destroy()
    }
  })

  return (
    <>
      <section className="md:py-112 relative mb-64 py-48">
        <div
          style={{ transform: mobile ? '' : 'translateX(-200px)', zIndex: -1 }}
          className="svg-shim pointer-events-none absolute top-0 left-0 right-0 text-gray-200 sm:right-auto sm:left-1/2 sm:bottom-0"
        >
          <svg
            viewBox="0 0 723 569"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="vertical-middle h-full w-auto max-w-none overflow-hidden"
          >
            <path
              d="M703.969 241.602L703.963 241.599C716.081 262.97 723 287.677 723 314C723 382.917 675.575 440.757 611.58 456.665L246.7 556.937C226.465 564.729 204.481 569 181.5 569C81.2603 569 0 487.74 0 387.5C0 353.244 9.49023 321.204 25.985 293.867L25.9688 293.875L141.512 77.5476C162.753 33.3052 207.123 2.2726 258.951 0.119583L258.959 0H264.719H390.999H508.5H509.999L510.002 0.00999319C551.85 0.567328 588.083 24.388 606.358 59.1292L606.359 59.125L703.969 241.602Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <div className="max-w-1040 mx-auto px-20">
          <div className="row items-center">
            <Col clx="col-12 md:col-6 md:order-2">
              <div className="mb-8 transform md:mb-0">
                <video
                  poster={`${CDN_URL}/web/home/poster-why_ringle.png`}
                  className="md:min-w-130 relative h-auto max-w-full scale-90 rounded-lg sm:-translate-x-1/4 sm:scale-110"
                  loop
                  muted
                  playsInline
                  autoPlay={true}
                  data-setup="{}"
                  style={{
                    boxShadow: '25px 60px 125px -25px rgba(44, 63, 88, 0.1), 16px 40px 75px -40px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <source
                    src={`${CDN_URL}/new-theme/tutor/winter_referral/referral_here_web_video.mp4`}
                    type="video/mp4"
                  />
                </video>
              </div>
            </Col>
            <Col
              clx={classNames('col-12 md:col-6 md:order-1', {
                'px-32': mobile,
              })}
            >
              <h1
                className={classNames(classNames({ 'text-d2': !mobile, 'text-d4': mobile }, 'font-medium'), ' text-d2')}
              >
                <span id="typeAnimation" className="font-bold text-purple-500"></span>
                <br />
                with Ringle
              </h1>
              <h3 style={{ letterSpacing: 'normal' }} className="text-h3 mb-32 text-gray-700">
                Where students and tutors
                <br />
                thrive together
              </h3>
              <EnterOrSignUp currentUser={currentUser} ctaId={TUTOR_LANDING_BTN_ID.GET_STARTED_1} />
            </Col>
          </div>
        </div>
      </section>
    </>
  )
}

const GoodParts = ({ mobile }: TutorHomePageChildrenPropsType) => {
  return (
    <>
      <section className="bg-gray-200 bg-opacity-50 py-96">
        <div className="max-w-1040 mx-auto px-20">
          <div className="row mb-40">
            <Col clx={classNames({ 'px-32': mobile })}>
              <h1
                style={{ lineHeight: 'normal', letterSpacing: mobile ? '-0.62px' : 'normal' }}
                className={classNames(classNames('text-d4 mb-24 font-medium', { 'text-d5': mobile }), 'text-d4')}
              >
                Teach professional English online{mobile ? <>&nbsp;</> : <br />}
                from the comfort of your own home
              </h1>
              <div className="mb-40 text-gray-700 sm:mb-0 ">
                Our mission is to break down linguistic barriers, bridge cultural gaps,
                <br />
                and allow talented global professionals to exchange knowledge
              </div>
            </Col>
            <Col auto>
              <img
                className="vertical-middle max-w-none overflow-hidden"
                src={`${CDN_URL}/landing/tutor/home/img-illustartion-1.svg`}
              />
            </Col>
          </div>
          <div className="row">
            <Col clx={classNames('col-12 md:col-4 mb-24', { 'px-32': mobile })}>
              <div
                style={{ height: '200px' }}
                className="relative flex min-w-0 flex-col items-center justify-center rounded-md border border-gray-100 bg-white p-8 text-center shadow"
              >
                <img className="vertical-middle max-w-none overflow-hidden" src="/assets/img/icons/img-flexible.svg" />
                <h3 className="text-h3 my-12">Flexible</h3>
                <div className="text-h6 m-0 text-gray-600">
                  Choose your own hours.
                  <br />
                  Work from home on your terms.
                </div>
              </div>
            </Col>
            <Col clx={classNames('col-12 md:col-4 mb-24', { 'px-32': mobile })}>
              <div
                style={{ height: '200px' }}
                className="relative flex min-w-0 flex-col items-center justify-center rounded-md border border-gray-100 bg-white p-8 text-center shadow"
              >
                <img
                  className="vertical-middle max-w-none overflow-hidden"
                  src="/assets/img/icons/img-networking.svg"
                />
                <h3 className="text-h3 my-12">Networking</h3>
                <div className="text-h6 m-0 text-gray-600">
                  Hone interpersonal skills
                  <br />
                  and cultural awareness along the way.
                </div>
              </div>
            </Col>
            <Col clx={classNames('col-12 md:col-4 mb-24', { 'px-32': mobile })}>
              <div
                style={{ height: '200px' }}
                className="relative flex min-w-0 flex-col items-center justify-center rounded-md border border-gray-100 bg-white p-8 text-center shadow"
              >
                <img
                  className="vertical-middle max-w-none overflow-hidden"
                  src="/assets/img/icons/img-hassle-free.svg"
                />
                <h3 className="text-h3 my-12">Hassle-free</h3>
                <div className="text-h6 m-0 text-gray-600">
                  Don't worry about preparing lesson plans.
                  <br />
                  Our Ringle packets do the work for you.
                </div>
              </div>
            </Col>
          </div>
        </div>
      </section>
    </>
  )
}

const VideoSection = ({ mobile, currentUser, videoRef }: TutorHomePageChildrenPropsType) => {
  return (
    <div className="relative z-[1]">
      <section
        style={{ background: 'linear-gradient(#F1F4F8 60%, #FFFFFF 40%)', zIndex: '-1' }}
        ref={videoRef}
        className="relative py-96"
      >
        <div className="max-w-1040 mx-auto px-20">
          <div className="row justify-center">
            <Col style={{ width: '1040px' }} clx={classNames({ 'pl-32': mobile })}>
              <img
                className="vertical-middle max-w-none overflow-hidden"
                src={`${CDN_URL}/landing/tutor/home/img-double-quote.svg`}
              />
              <h1
                style={{
                  lineHeight: 'normal',
                  letterSpacing: mobile ? '-0.62px' : 'normal',
                }}
                className={classNames(classNames('mt-12 mb-24 sm:mb-40', { 'text-h1': mobile }), 'text-d4')}
              >
                Ringle is a perfect job for students
                {mobile ? <>&nbsp;</> : <br />}
                looking for flexibility, allowing students
                {mobile ? <>&nbsp;</> : <br />}
                to schedule lessons on their own time.
              </h1>
            </Col>
          </div>
          <div className="row relative mb-32 justify-center">
            <div className="relative">
              <iframe
                width={'1040'}
                height={mobile ? '' : '585'}
                className="w-full"
                src={`https://player.vimeo.com/video/771874810`}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
            <Script src="https://player.vimeo.com/api/player.js"></Script>
          </div>
          <div className="flex items-center justify-center">
            <EnterOrSignUp currentUser={currentUser} ctaId={TUTOR_LANDING_BTN_ID.GET_STARTED_2} />
          </div>
        </div>
      </section>
      <div style={{ transform: 'translateY(100px)' }} className="relative">
        <div className="shape-blur svg-shim pointer-events-none absolute top-0 right-0 bottom-0">
          <img
            className="vertical-middle max-w-none overflow-hidden"
            src={`${CDN_URL}/landing/tutor/home/pattern-2.svg`}
          />
        </div>
      </div>
    </div>
  )
}

const MaterialTitle = ({ mobile }: TutorHomePageChildrenPropsType) => {
  return (
    <CenterContainer relative z2>
      <h1 className="mb-96 text-[32px] font-bold sm:text-[52px]" style={{ lineHeight: 'normal' }}>
        Not your average
        <br />
        tutoring company
      </h1>
      <h2 className={classNames(classNames('mb-0 font-medium text-gray-700', { 'text-h1': mobile }), 'text-d4')}>
        Carefully{mobile && <br />} cultivated lesson materials
      </h2>
      <div className="my-32 text-gray-600">
        We know you're all busy people, which is why we provide all lesson materials for Ringle lessons.{' '}
        {!mobile && <br />}
        Have discussions with global professionals on topics as varied as politics, fashion, business and sport.
      </div>
    </CenterContainer>
  )
}

const Companies = ({ mobile }: TutorHomePageChildrenPropsType) => {
  return (
    <>
      <div style={{ transform: 'translateY(200px)', zIndex: '-1' }} className="relative">
        <div className="shape-blur svg-shim pointer-events-none absolute right-0 bottom-0 left-0 overflow-hidden">
          <img
            className="vertical-middle max-w-none overflow-hidden"
            src={`${CDN_URL}/landing/tutor/home/pattern-1.svg`}
          />
        </div>
      </div>
      <section className="pt-5 pb-48 sm:pt-48">
        <div className="max-w-1040 mx-auto px-20">
          <div className="row justify-center">
            <Col clx={classNames({ 'text-right': !mobile })}>
              <h1
                style={{ lineHeight: 'normal', letterSpacing: mobile ? '-0.8px' : 'normal' }}
                className={classNames(classNames('mb-0 font-medium text-gray-700', { 'text-h1': mobile }), 'text-d4')}
              >
                Diverse, motivated{mobile && <br />} students
              </h1>
              <div className="mt-32 mb-64 font-light sm:mt-32">
                Our students work in an incredibly broad range of industries and academic fields, from Big Data to Fine
                Art.
                <br />
                They're hard-working, high-achieving and, most importantly, eager to learn.
              </div>
              <img
                src={
                  mobile
                    ? `${CDN_URL}/landing/tutor/home/img-company-mobile-ver%403x.jpg`
                    : `${CDN_URL}/landing/tutor/home/img-company-pc-ver%403x.jpg`
                }
                width="100%"
              />
            </Col>
          </div>
        </div>
      </section>
      <div style={{ transform: 'translateY(-200px)', zIndex: -1 }} className="relative">
        <div className="shape-blur svg-shim pointer-events-none absolute top-0 right-0 bottom-0">
          <img
            className="vertical-middle max-w-none overflow-hidden"
            src={`${CDN_URL}/landing/tutor/home/pattern-3.svg`}
          />
        </div>
      </div>
    </>
  )
}

const Fans = ({ mobile }: TutorHomePageChildrenPropsType) => {
  const tutor_material = [
    {
      src: `${CDN_URL}/landing/tutor/home/Komal.jpeg`,
      p: 'Since starting Ringle, I have developed a more vibrant and worldly perspective. Ringle continues to help me grow as a lifelong learner and I am thankful to be a part of this team!',
      name: 'Komal',
      school: 'University of Michigan',
    },
    {
      src: `${CDN_URL}/landing/tutor/home/Isobel`,
      p: 'Not only is Ringle flexible, conforming easily to my busy grad-student schedule, but meeting with students truly brightens my day.',
      name: 'Isobel',
      school: 'New York University',
    },
    {
      src: `${CDN_URL}/landing/tutor/home/Juan.png`,
      p: 'Ringle is unique in being able to offer both a high return of investment and the flexibility required as a college student. All while being able to talk to industry leaders from the other side of the world!',
      name: 'Juan',
      school: 'Harvard University',
    },
    {
      src: `${CDN_URL}/images/tutors/81804_low.jpeg`,
      p: 'I am so grateful to work for Ringle, where I not only get to meet the kindest students but also learn so much about their different career paths and backgrounds. It’s so fulfilling to have interesting conversations and truly enjoy my job as a tutor.',
      name: 'Tulsi',
      school: 'Yale University',
    },
    {
      src: `${CDN_URL}/images/tutors/82037_low.jpeg`,
      p: "Since starting Ringle, and as a Korean language learner, I've gained a much more worldly perspective from speaking to various professionals worldwide. I'm so honored and grateful to be a part of this wonderful team, and to continue expanding my horizons!",
      name: 'Sandi',
      school: 'Stanford University',
    },
    {
      src: `${CDN_URL}/images/tutors/82388_low.jpeg`,
      p: "I continue to be blown away by Ringle's students, who are passionate, curious and driven. Each lesson offers a glimpse into a unique part of the world, making teaching both fulfilling and intellectually exciting.",
      name: 'Ezra',
      school: 'Stanford University',
    },
    {
      src: `${CDN_URL}/images/tutors/51189_low.jpeg`,
      p: "Ringle has provided me the opportunity to work with and learn from other, like-minded individuals in healthcare. I believe I'll be a stronger future physician through the conversations I have with students on a daily basis.",
      name: 'Ryan',
      school: 'Alpert Medical School of Brown University',
    },
    {
      src: `${CDN_URL}/images/tutors/54368_low.jpeg`,
      p: 'As a tutor, I have gained invaluable communication skills and understanding of the global perspective. As an ambassador, Ringle supported me in the development of my professional skills and experience, and I am so grateful for the opportunities Ringle has and continues to provide to their tutors.',
      name: 'Sophia',
      school: 'Yale University',
    },
  ]
  useTriggerResize()

  return (
    <>
      <section className={classNames(SectionClass)}>
        <div className="max-w-1040 mx-auto overflow-hidden px-20">
          <div className="row mb-64 sm:mb-96">
            <Col clx="col-12 md:col-10 lg:col-8">
              <h2
                style={{ lineHeight: 'normal', letterSpacing: mobile ? '-1.3px' : 'normal' }}
                className={classNames(classNames('mb-0 font-bold', { 'text-d4': mobile, 'text-d2': !mobile }))}
              >
                Don't just
                <br />
                take our word for it!
              </h2>
            </Col>
          </div>
          <div className="row mb-96 items-center">
            <Col clx="col-12">
              <Slider
                focusOnSelect={true}
                initialSlide={0}
                dots={false}
                slidesToShow={1}
                slidesToScroll={1}
                infinite={true}
                draggable={false}
                lazyLoad={'ondemand'}
                nextArrow={<SliderNextBtn />}
                prevArrow={<SliderPrevBtn />}
              >
                {tutor_material.map((item, i) => (
                  <div
                    key={i}
                    style={{ flex: '1 1 auto' }}
                    className="relative flex min-w-0 flex-col items-center rounded-md border border-gray-100 bg-white py-24"
                  >
                    <div style={{ flexGrow: 0 }} className="relative flex min-w-0 flex-col rounded-md bg-white">
                      <div className="row p-36">
                        <Col clx="col-12 md:col-4 md:pl-24 flex justify-center items-center">
                          <div className="mb-24 hidden sm:block md:mb-0">
                            <AvatarImg size={172} src={item.src} />
                          </div>
                        </Col>
                        <Col clx="col-12 md:col-8 md:pr-48">
                          <img
                            className="vertical-middle max-w-none overflow-hidden"
                            src={`${CDN_URL}/landing/tutor/home/img-double-quote.svg`}
                          />
                          <h3 style={{ lineHeight: 1.8 }} className="text-h3 mt-8">
                            {item.p}
                          </h3>
                          <div className="mb-24 mt-8 block sm:hidden md:mb-0">
                            <AvatarImg size={48} src={item.src} />
                          </div>
                          <div className="mt-24 text-gray-600">
                            {item.name}, {item.school}
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </Col>
          </div>
          <div className="row">
            <Col clx="col-12 md:col-6 flex content-between flex-wrap" data-aos="fade-right">
              <h1 className={classNames(classNames('mb-8 font-medium', { 'text-h1': mobile }), 'text-d4')}>
                Bright and Scholarly,
                <br />
                Ringle Tutors
              </h1>
              <h3
                className={classNames(classNames('text-h3 mt-24', { h4: mobile }), [
                  mobile ? 'font-medium' : 'text-h3',
                ])}
              >
                We value all hardworking and ambitious
                <br />
                individuals who are passionate about
                <br />
                sharing their knowledge.
              </h3>
              <div className="row mt-24">
                <Col clx="col-4">
                  <div className="flex items-end">
                    <h1 className="text-d4 mb-4 text-purple-500">70</h1>
                    <h2 className="ml-4 mb-4 text-purple-500">%</h2>
                    <br />
                  </div>
                  <h5 className="text-h5 mb-0 text-gray-700">
                    Top
                    <br />
                    30 Schools
                    <br />
                    in U.S
                  </h5>
                </Col>
                <Col clx="col-4">
                  <div className="flex items-end text-purple-500">
                    <h1 className="text-d4 mb-4">60</h1>
                    <h2 className="ml-4 mb-4">%</h2>
                  </div>
                  <h5 className="text-h5 mb-0 text-gray-700">
                    Current
                    <br />
                    Undergraduate
                    <br />
                    students
                  </h5>
                </Col>
                <Col clx="col-4">
                  <div className="flex items-end text-purple-500">
                    <h1 className="text-d4 mb-4">75</h1>
                    <h2 className="ml-4 mb-4">%</h2>
                    <br />
                  </div>
                  <h5 className="text-h5 mb-0 text-gray-700">
                    Experienced
                    <br />
                    Professionals
                    <br />& Interns
                  </h5>
                </Col>
              </div>
            </Col>
            <Col clx="col-12 md:col-6 mt-32 sm:mt-0">
              <img
                src={`${CDN_URL}/landing/tutor/home/img-university-black.png`}
                alt="..."
                className="img-fluid mb-32 shadow sm:mb-0"
                data-aos="fade-left"
              />
            </Col>
          </div>
        </div>
      </section>
      <div style={{ transform: 'translateY(-200px)', zIndex: -1 }} className="relative">
        <div className="shape-blur svg-shim pointer-events-none absolute right-0 bottom-0 left-0">
          <img
            className="vertical-middle max-w-none overflow-hidden"
            src={`${CDN_URL}/landing/tutor/home/pattern-4.svg`}
          />
        </div>
      </div>
    </>
  )
}

const GetStarted = ({ mobile }: TutorHomePageChildrenPropsType) => {
  return (
    <>
      <section
        style={{
          background: mobile ? 'linear-gradient(#FFFFFF 50%, #F9FBFD 50%)' : 'none',
        }}
        className="overflow-hidden py-80"
      >
        <div className="max-w-1040 mx-auto px-20">
          <div className="flex justify-center">
            <h2
              className={classNames(
                classNames('font-bold', {
                  'text-d4': mobile,
                  'text-d2': !mobile,
                }),
                'mb-[12px]'
              )}
            >
              Get started now!
            </h2>
          </div>
          <div className="flex justify-center">
            <h2 className={classNames(classNames('mb-0', { 'text-d8': mobile, 'text-h1': !mobile }))}>
              It's as easy as 1, 2, 3
            </h2>
          </div>
          <div className="row sm:mt-128 mt-96 flex items-center justify-center">
            <Col clx="col-12 md:col-auto" px20>
              <div className="relative flex min-w-0 flex-col rounded-md border border-gray-100 bg-white shadow">
                <div className="p-[32px]">
                  <img
                    className="vertical-middle max-w-none overflow-hidden"
                    src={`${CDN_URL}/landing/tutor/home/img-mock-process-1.svg`}
                  />
                </div>
              </div>
            </Col>
            <Col clx="col-12 md:col-auto" px20>
              <div className="mb-8 mt-32 flex items-center sm:mt-24">
                <h1 className={classNames(classNames('m-0', { 'text-h1': mobile }), 'text-d4')}>Step 1.</h1>
                <ClockIcon className="ml-4 text-purple-500" size={17} />
                <div className="mb-0 ml-4 text-purple-500">10 min</div>
              </div>
              <h2 className={classNames(classNames({ 'text-h3': mobile }), 'text-h1 mb-[12px]')}>
                Submit an Application
              </h2>
              <h3 className={classNames(classNames({ 'text-h4': mobile }), 'text-h3')}>
                Our fuss-free application takes less than <span className="font-medium">10 minutes</span> to complete.
              </h3>
            </Col>
          </div>
          {!mobile && (
            <>
              <div style={{ transform: 'translateX(200px)' }}>
                <div
                  style={{
                    height: '98px',
                    width: '607px',
                    borderRight: '0px',
                    borderTop: '0px',
                    borderStyle: 'dashed',
                    borderColor: '#E2E9F2',
                  }}
                  className="border-2"
                />
                <div
                  style={{
                    height: '98px',
                    width: '607px',
                    borderLeft: '0px',
                    borderTop: '0px',
                    borderBottom: '0px',
                    borderStyle: 'dashed',
                    borderColor: '#E2E9F2',
                  }}
                  className="border-2"
                />
              </div>
            </>
          )}
          <div className="row mt-96 flex items-center justify-center sm:mt-0">
            <Col clx="col-12 md:col-auto sm:order-2" px20>
              <div className="relative flex min-w-0 flex-col rounded-md border border-gray-100 bg-white shadow">
                <div className="p-[32px]">
                  <img
                    className="vertical-middle max-w-none overflow-hidden"
                    src={`${CDN_URL}/landing/tutor/home/img-mock-process-2.svg`}
                  />
                </div>
              </div>
            </Col>
            <Col clx="col-12 md:col-auto sm:order-1" px20>
              <div className="mb-8 mt-32 flex items-center sm:mt-24">
                <h1 className={classNames(classNames('m-0', { 'text-h1': mobile }), 'text-d4')}>Step 2.</h1>
                <ClockIcon className="ml-4 text-purple-500" size={17} />
                <div className="mb-0 ml-4 text-purple-500">20 min</div>
              </div>
              <h2 className={classNames(classNames({ h3: mobile }), 'text-h1 mb-[12px]')}>Schedule a mock session</h2>
              <h3 className={classNames(classNames({ h4: mobile }), 'text-h3')}>
                Show off your tutoring skills in a <span className="font-medium">20 minute</span> mock session.
              </h3>
            </Col>
          </div>
          {!mobile && (
            <>
              <div style={{ transform: 'translateX(200px)' }}>
                <div
                  style={{
                    height: '98px',
                    width: '607px',
                    borderLeft: '0px',
                    borderTop: '0px',
                    borderStyle: 'dashed',
                    borderColor: '#E2E9F2',
                  }}
                  className="border-2"
                />
                <div
                  style={{
                    height: '98px',
                    width: '607px',
                    borderRight: '0px',
                    borderTop: '0px',
                    borderBottom: '0px',
                    borderStyle: 'dashed',
                    borderColor: '#E2E9F2',
                  }}
                  className="border-2"
                />
              </div>
            </>
          )}
          <div className="row mt-96 flex items-center justify-center sm:mt-0">
            <Col clx="col-12 md:col-auto" px20>
              <div className="relative flex min-w-0 flex-col rounded-md border border-gray-100 bg-white shadow">
                <div className="p-[32px]">
                  <img
                    className="vertical-middle max-w-none overflow-hidden"
                    src={`${CDN_URL}/landing/tutor/home/img-mock-process-3.svg`}
                  />
                </div>
              </div>
            </Col>
            <Col clx="col-7 md:col-7" px20>
              <div className="sm:mt-76 mb-8 mt-24 flex items-center">
                <h1 className={classNames(classNames('m-0', { 'text-h1': mobile }), 'text-d4')}>Step 3.</h1>
                <ClockIcon className="ml-4 text-purple-500" size={17} />
                <div className="mb-0 ml-4 text-purple-500">40 min</div>
              </div>
              <h2 className={classNames(classNames({ 'text-h3': mobile }), 'text-h1 mb-[12px]')}>
                Complete the Orientation Process
              </h2>
              <h3 className={classNames(classNames({ 'text-h4': mobile }), 'text-h3')}>
                After watching the three short videos introducing Ringle, site navigation, and lesson instructions, you
                will be asked to take a short quiz to demonstrate your understanding.
              </h3>
            </Col>
          </div>
        </div>
        <div
          style={{
            transform: mobile ? 'translateY(-200px)' : 'translateY(80px)',
            zIndex: -1,
          }}
          className="relative"
        >
          <div
            style={{ height: '1400px', transformOrigin: 'top center' }}
            className="svg-shim pointer-events-none absolute right-0 bottom-0 left-0"
          >
            <img
              className="vertical-middle height-auto w-full max-w-none scale-x-[2] overflow-hidden"
              src={`${CDN_URL}/landing/tutor/home/pattern-5.svg`}
              alt="Pattern"
            />
          </div>
        </div>
      </section>
    </>
  )
}

const JoinRingle = ({ mobile, currentUser }: TutorHomePageChildrenPropsType) => {
  return (
    <>
      <section className="py-80">
        <div className="max-w-1040 mx-auto px-20">
          <div className="row justify-center">
            <Col clx="col-12 lg:col-10 text-center">
              <h1 className="text-d4 mb-8 font-bold">Start working{mobile && <br />} with Ringle today</h1>
              {!mobile && (
                <h2 className="text-h2 mb-0">
                  to help professionals overcome language barriers,
                  <br />
                  while building your own network
                </h2>
              )}
              <EnterOrSignUp currentUser={currentUser} ctaId={TUTOR_LANDING_BTN_ID.GET_STARTED_3} />
            </Col>
          </div>
        </div>
      </section>
    </>
  )
}

type ContentsPropsType = {
  result: any
  currentUser: CurrentUser
}
const TutorLandingHomeContents = ({ result, currentUser }: ContentsPropsType) => {
  const router = useRouter()
  const videoRef = useRef(null)
  const queryClient = useQueryClient()
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [name, setName] = useState('')

  useEffect(() => {
    setIsMobile(window.innerWidth < 576 ? true : false)
  }, [])

  useEffect(() => {
    getName()
  }, [])

  //개인정보 입력 이후 레쥬메 제출 ~ 수락 사이에 다시 접근할 때 유저 데이터 업데이트 필요
  useEffect(() => {
    if (currentUser) {
      queryClient.invalidateQueries(userQueryApi.getQueryKey())
    }
  }, [])

  const getName = async () => {
    const url = tutor_apis.referral.referralCode(router.query.friend)

    const res = await axiosGet(url)
    console.log('res', res)

    if (res?.user_name) {
      setName(res.user_name)
      console.log('res', res)
    }
  }

  return (
    <>
      {result && result.show_referral && <>{!currentUser && <BandBanner name={name} />}</>}
      <Welcome currentUser={currentUser} mobile={isMobile} />
      <GoodParts mobile={isMobile} />
      <VideoSection currentUser={currentUser} mobile={isMobile} videoRef={videoRef} />
      <LandingMaterials sectionTitle={<MaterialTitle mobile={isMobile} />} />
      <Companies mobile={isMobile} />
      <Fans mobile={isMobile} />
      <GetStarted mobile={isMobile} />
      <JoinRingle currentUser={currentUser} mobile={isMobile} />
    </>
  )
}
export default TutorLandingHomeContents
